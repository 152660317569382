<template>
  <div>
    <div class="appLiBox">
      <div class="flex"
           style="border: 1px solid #2878ff;">
        <div :class="navIndex == 1 ? 'navBox active' : 'navBox' "
             @click="navBar(1)">
          证书申领
        </div>
      </div>
      <div v-if="navIndex == 1">
        <div class="flex"
             style="margin-top: 8px;align-items: center;">
          <div class="su">

          </div>
          <div style="font-weight: bold;font-size:18px;margin-left: 12px;">
            可申请证书列表
          </div>
          <div style="color: red;margin-left: 350px;">
            *此列表不展示公需课证书，公需课证书不可申请邮寄
          </div>
        </div>
        <el-table ref="multipleTable"
                  tooltip-effect="dark"
                  :data="certList"
                  style="width: 100%;margin-top: 8px;"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection"
                           width="55">
          </el-table-column>
          <el-table-column label="证书名称"
                           align="center"
                           prop="CERT_NAME">
          </el-table-column>
          <el-table-column prop="CERT_NO"
                           label="证书编号"
                           align="center"
                           width="260">
          </el-table-column>
          <el-table-column prop="CERT_TYPE_NAME"
                           label="类型"
                           align="center"
                           show-overflow-tooltip>
           <template slot-scope="scope">
              <span style="color: #4077C0;" v-if="scope.row.CERT_TYPE">{{ scope.row.CERT_TYPE == '0701' ? '公需' : scope.row.CERT_TYPE == '0702' ? '专业' : scope.row.CERT_TYPE == '0703' ? '转岗' : '' }}</span>
              <span v-else>{{ scope.row.CERT_TYPE_NAME }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="YEAR"
                           label="年度"
                           align="center"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="CREDIT"
                           label="完成学时"
                           align="center"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="address"
                           label="操作"
                           align="center"
                           show-overflow-tooltip>

            <template slot-scope="scope">
              <router-link style="color: #ff9f53;cursor: pointer;"
                           v-if="scope.row.CERT_TYPE !== '0700'"
                           :to="{path:'/cert',query:{id:scope.row.ID}}"
                           target="_blank">证书查看</router-link>

              <div style="color: #ff9f53;cursor: pointer;"
                   v-if="scope.row.CERT_TYPE == '0700'"
                   @click="gox(scope.row)">证书查看</div>

              <span v-if="scope.row.text == '已添加'"
                    style="cursor: pointer;color: red;margin-left: 15px;"
                    @click="delet(scope.$index)">删除</span>
            </template>

          </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: center;">
          <el-button @click="apply"
                     style="width: 180px;background: #2878ff;color: #fff;margin-top: 8px;">
            申请邮寄
          </el-button>
        </div>
        <div class="flex"
             style="margin-top: 18px;align-items: center;">
          <div class="su">

          </div>
          <div style="font-weight: bold;font-size:18px;margin-left: 12px;width: 120px;">
            添加证书申领
          </div>

          <div style="margin-left: 54px;"
               class="flex">
            <el-input style="width: 300px;height: 40px;"
                      prefix-icon="el-icon-search"
                      v-model="cardno"
                      placeholder="请输入身份证编号"></el-input>
            <el-button type="primary"
                       @click="searCar"
                       icon="el-icon-search"
                       style="margin-left: 8px;">搜索</el-button>
          </div>

          <div style="color: red;margin-left: 50px;">
            *公需课证书在这里无法查询到
          </div>
        </div>
        <el-table tooltip-effect="dark"
                  :data="searcertList"
                  style="width: 100%;margin-top: 8px;">
          <el-table-column label="证书名称"
                           align="center"
                           prop="CERT_NAME">
          </el-table-column>
          <el-table-column prop="CERT_NO"
                           label="证书编号"
                           align="center"
                           width="260">
          </el-table-column>
          <el-table-column prop="CERT_TYPE"
                           label="类型"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="color: #4077C0;">{{ scope.row.CERT_TYPE == '0701' ? '公需' : scope.row.CERT_TYPE == '0702' ? '专业' : scope.row.CERT_TYPE == '0703' ? '转岗' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="YEAR"
                           label="年度"
                           align="center"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="CREDIT"
                           label="完成学时"
                           align="center"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="address"
                           label="操作"
                           align="center"
                           show-overflow-tooltip>

            <template slot-scope="scope">
              <el-button :key="index"
                         :disabled="scope.row.text == '已添加'"
                         style="cursor: pointer;color: #ff9f53;"
                         @click="add(scope.row)">{{ scope.row.text }}</el-button>
            </template>

          </el-table-column>
        </el-table>
        <div class="flex"
             style="margin-top: 8px;align-items: center;">
          <div class="su">

          </div>
          <div style="font-weight: bold;font-size:18px;margin-left: 12px;">
            申请邮寄记录
          </div>
          <div style="color: red;margin-left: 350px;">
          </div>
        </div>
        <el-table tooltip-effect="dark"
                  :data="orderExpressList"
                  style="width: 100%;margin-top: 8px;">

          <el-table-column prop="EXPRESS_NO"
                           label="流水号"
                           align="center"
                           width="220">
          </el-table-column>
          <el-table-column label="证书列表"
                           align="center"
                           width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.certList">
                <div v-for="item in scope.row.certList"
                     :key="item.ID" style="margin-right: 5px;">
                  <router-link style="color: #ff9f53;cursor: pointer;"
                               :to="{path:'/cert',query:{id:item.ID}}"
                               v-if="item.CERT_TYPE !== '0700'"
                               target="_blank">{{ item.CERT_NAME }}</router-link>
                  <div v-else
                       style="color: #2878FF;cursor: pointer;"
                       @click="gox()">{{ item.CERT_NAME }}</div>
                </div>

              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CREATETIME"
                           label="申领时间"
                           align="center"
                           width="220"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="运费状态"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.EXPRESS_STATE == '01' ? '未支付':scope.row.EXPRESS_STATE == '02' ? '支付中':scope.row.EXPRESS_STATE == '20' ? '已支付':''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="物流状态"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.LOGISTICE_STATE == '01' ? '未寄送':scope.row.LOGISTICE_STATE == '02' ? '已寄送':scope.row.LOGISTICE_STATE == '03' ? '现场领':scope.row.LOGISTICE_STATE == '02' ? '已寄送':scope.row.LOGISTICE_STATE == '04' ? '已领取' : ''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer;color: #ff9f53;"
                    @click="handDetils(scope.row)">查看详情</span>
              <span v-if="scope.row.EXPRESS_STATE == '01'"
                    style="cursor: pointer;color: #ff9f53;margin-left: 5px;"
                    @click="qx(scope.row)">取消</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 减免方式  -->
      <el-dialog :visible.sync="applydialogVisible"
                 width="50%">
        <div>
          <div class="flex"
               style="align-items: center;">
            <div class="su">

            </div>
            <div style="font-weight: bold;font-size:18px;margin-left: 12px;">
              减免方式
            </div>
            <div style="color: red;margin-left: 350px;">
            </div>
          </div>

          <div style="margin-top: 20px;font-size: 16px;"
               v-if="balance- totalPrice >= 0">
            <el-checkbox v-model="zhdk">账户抵扣： ￥{{ totalPrice }} 剩余{{ balance- totalPrice}}元</el-checkbox>
          </div>
          <div style="margin-top: 20px;font-size: 16px;"
               v-else>
            账户余额不足，无法抵扣
          </div>
          <div>
            <div class="flex"
                 style="align-items: center;margin-top: 12px;justify-content: space-between;">
              <div class="flex"
                   style="align-items: center;">
                <div class="su">

                </div>
                <div style="font-weight: bold;font-size:18px;margin-left: 12px;">
                  选择寄送地址
                </div>
              </div>

              <div style="color: #2878ff;cursor: pointer;"
                   @click="address">
                + 添加寄送地址
              </div>
            </div>
          </div>

          <div style="margin-top: 12px;">
            <el-radio-group v-model="radio">
              <div v-for="item in ressList"
                   :key="item.id"
                   style="margin-top:8px;">
                <el-radio-group v-model="addressid">
                  <el-radio :label="item.id">
                    姓名：{{ item.name }} 电话：{{ item.phone }} 地址：{{ item.address }}
                  </el-radio>
                </el-radio-group>
              </div>

            </el-radio-group>
          </div>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="applydialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="pay">缴纳运费</el-button>
        </span>
      </el-dialog>

      <!-- 添加地址 -->
      <el-dialog :visible.sync="addDialogVisible"
                 title="添加地址"
                 width="30%">
        <div class="flex"
             style="justify-content: center;">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="姓名 :"
                          prop="name">
              <el-input style="width: 200px;"
                        v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码  :"
                          prop="phone">
              <el-input style="width: 200px;"
                        v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="所在地区 :"
                          prop="area">
              <el-cascader v-model="ruleForm.area"
                           :options="optionsArea"
                           :props="props"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址  :"
                          prop="address">
              <el-input v-model="ruleForm.address"
                        style="width: 280px;"
                        type="textarea"
                        :rows="3"></el-input>
            </el-form-item>
            <el-form-item label="邮政编码 :"
                          prop="postcode">
              <el-input style="width: 200px;"
                        v-model="ruleForm.postcode"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="ruleForm.isdefault"
                           label="02">设为默认收货地址</el-checkbox>
            </el-form-item>
            <el-form-item>

              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary"
                         @click="addresss('ruleForm')">确定添加</el-button>
            </el-form-item>

          </el-form>
        </div>
      </el-dialog>

    </div>
    <!-- 证书详情 -->
    <el-dialog :visible.sync="certDialogVisible"
               title="证书详情"
               width="40%">
      <table class="table1">
        <tbody>
          <tr>
            <td width="30%"
                class="td1">流水号</td>
            <td width="60%"> {{expressDetail.expressNo}}</td>
          </tr>
          <tr>
            <td class="td1">缴费单号</td>
            <td> {{expressDetail.expressPayNo}}</td>
          </tr>
          <tr>
            <td class="td1">姓名</td>
            <td> {{expressDetail.realname}}</td>
          </tr>
          <tr>
            <td class="td1">身份证</td>
            <td> {{expressDetail.cardno}}</td>
          </tr>
          <tr>
            <td class="td1">运单金额</td>
            <td> {{expressDetail.expressTotalMoney}}</td>
          </tr>
          <tr>
            <td class="td1">支付金额</td>
            <td> {{expressDetail.expressPrice}}</td>
          </tr>
          <tr>
            <td class="td1">支付方式</td>
            <td>
              <span v-if="expressDetail.expressPayType == '00'">其它 </span>
              <span v-if="expressDetail.expressPayType == '01'">在线支付 </span>
              <span v-if="expressDetail.expressPayType == '02'">学习帐户 </span>
              <span v-if="expressDetail.expressPayType == '03'">现金面付 </span>
              <span v-if="expressDetail.expressPayType == '04'">银行汇款 </span>
              <span v-if="expressDetail.expressPayType == '40'">银行汇款(未入帐) </span>
              <span v-if="expressDetail.expressPayType == '05'">邮局汇款 </span>
              <span v-if="expressDetail.expressPayType == '06'">支付宝支付 </span>
              <span v-if="expressDetail.expressPayType == '07'">手机支付(支付宝) </span>
              <span v-if="expressDetail.expressPayType == '08'">刷卡支付 </span>
              <span v-if="expressDetail.expressPayType == ''">无 </span>
              <span v-if="expressDetail.expressPayType == '0901'">微信公众号 </span>
              <span v-if="expressDetail.expressPayType == '0902'">微信WAP </span>
              <span v-if="expressDetail.expressPayType == '0903'">微信扫码 </span>
              <span v-if="expressDetail.expressPayType == '0904'">微信APP </span>
              <span v-if="expressDetail.expressPayType == '10'">手工 </span>
              <span v-if="expressDetail.expressPayType == '11' ">银联支付 </span>
              <span v-if="expressDetail.expressPayType == '12'">手机银联 </span>
              <span v-if="expressDetail.expressPayType == '13'">未到账 </span>
            </td>
          </tr>
          <tr>
            <td class="td1">下单时间</td>
            <td> {{expressDetail.createTime}}</td>
          </tr>
          <tr>
            <td class="td1">支付时间</td>
            <td> {{expressDetail.expressPayTime}}</td>
          </tr>
          <tr>
            <td class="td1">支付渠道</td>
            <td>
              <span v-if="expressDetail.expressPaySource == '01'">电脑web</span>
              <span v-if="expressDetail.expressPaySource == '02'">手机网站</span>
              <span v-if="expressDetail.expressPaySource == '03'">手机app</span>
              <span v-if="expressDetail.expressPaySource == '04'">公众号</span>
            </td>
          </tr>
          <tr>
            <td class="td1">减免清单</td>
            <td>
              <span v-if="expressDetail.expressAccountMoney!==0">减免金额: {{expressDetail.expressAccountMoney}} 元，减免方式:学习账户</span>
              <span v-if="expressDetail.expressIntegralMoney !== 0 ">减免金额: {{expressDetail.expressIntegralMoney }}元，减免方式:积分抵用</span>
            </td>
          </tr>
          <tr>
            <td class="td1">支付状态</td>
            <td>
              <span v-if="expressDetail.expressState == '01'">未支付</span>
              <span v-if="expressDetail.expressState == '02'">支付中</span>
              <span v-if="expressDetail.expressState == '20'">已支付</span>
            </td>
          </tr>
          <tr>
            <td class="td1">收件信息</td>
            <td>
              收件人: {{expressDetail.addressName }},电话: {{expressDetail.addressPhone }},地址: {{expressDetail.addressDesc }}
            </td>
          </tr>
          <tr>
            <td class="td1">物流状态</td>
            <td>
              <span v-if="expressDetail.lodisticeState == '01'">未寄送</span>
              <span v-if="expressDetail.lodisticeState == '02'">已寄送</span>
              <span v-if="expressDetail.lodisticeState == '03'">现场领</span>
              <span v-if="expressDetail.lodisticeState == '04'">已领取</span>
            </td>
          </tr>
          <tr>
            <td class="td1">物流公司</td>
            <td>
              <span v-if="expressDetail.expressCom == 'zhongtong'">中通速递</span>
              <span v-if="expressDetail.expressCom == 'yuantong'">圆通速递</span>
              <span v-if="expressDetail.expressCom == 'yunda'">韵达快运</span>
              <span v-if="expressDetail.expressCom == 'shunfeng'">顺丰速递</span>
              <span v-if="expressDetail.expressCom == 'shentong'">申通速递</span>
            </td>
          </tr>
          <tr>
            <td class="td1">物流号</td>
            <td> {{expressDetail.lodisticeNo}}</td>
          </tr>
          <tr>
            <td class="td1">配送时间</td>
            <td> {{expressDetail.lodisticeTime}}</td>
          </tr>
        </tbody>
      </table>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="certDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import {
  getExpressDetail,
  toApplyCert,
  toSearchByCardNo,
  getUserAddressList,
  getAllAreaList,
  addUserAddress,
  toGenExpress,
  toCancelExpress
} from '@/api/cert.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      navIndex: 1,
      certList: [], //待申领列表
      orderExpressList: [], //申请记录
      cardno: '',
      searcertList: [], //搜索后的
      index: 1,
      seletList: [], //申请数据
      balance: '', //账户余额
      totalPrice: '', //邮费
      applydialogVisible: false, //减免方式的弹框
      zhdk: false,
      radio: '',
      ressList: [], //用户的地址
      addDialogVisible: false, //添加地址
      certDialogVisible: false,
      ruleForm: {},
      optionsArea: [],
      props: {
        value: 'CODEID',
        label: 'CITYNAME'
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号不符合规则！', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择区域', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址！', trigger: 'blur' }],
        postcode: [{ required: true, message: '请输入邮政编码！', trigger: 'blur' }]
      },
      addressid: '', //选中的地址
      expressDetail: {}, //详情数据
      tmUrl: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //公需课证书
    gox() {
      this.$confirm('公需课证书需要跳转到公需课平台查看，是否跳转?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          window.open(this.tmUrl);
        })
        .catch(() => {});
    },
    //查看申领证书详情
    handDetils(row) {
      getExpressDetail({ expressId: row.ID }).then(res => {
        this.expressDetail = res.data.data;
        this.certDialogVisible = true;
      });
    },
    //取消运单
    qx(row) {
      toCancelExpress({ expressId: row.ID }).then(res => {
        this.toApplyCert();
      });
    },
    //确定添加
    addresss(formName) {
      if (!Array.isArray(this.ruleForm.area)) {
        this.$message('请选择地区！');
        return false;
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.area = this.ruleForm.area[this.ruleForm.area.length - 1];
          this.ruleForm.isdefault = this.ruleForm.isdefault == true ? '02' : '01';
          addUserAddress(this.ruleForm).then(res => {
            console.log(res.data);
            this.addDialogVisible = false;
            getUserAddressList({}).then(res => {
              console.log(res.data.data);
              this.ressList = res.data.data;
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //添加地址弹框
    address() {
      this.addDialogVisible = true;
      getAllAreaList({}).then(res => {
        this.optionsArea = res.data.data;
      });
    },
    //缴纳运费
    pay() {
      if (this.addressid == '') {
        this.$message('您未选中邮寄地址，如无地址请先添加！');
        return false;
      }
      let data = {
        certid: '',
        addressid: '',
        receiveType: '01',
        derateType: '',
        batchMailFlag: '01'
      };
      console.log(this.addressid);
      data.addressid = this.addressid;
      if (this.zhdk) {
        data.derateType = '01';
      } else {
        data.derateType = '';
      }
      if (this.seletList.length == 1) {
        data.certid = this.seletList[0].ID;
        data.batchMailFlag = '01';
      } else {
        data.certid = [];
        this.seletList.forEach(item => {
          data.certid.push(item.ID);
        });
        data.certid = data.certid.toString();
      }

      toGenExpress(data).then(res => {
        if (res.data.data.result == 2) {
          this.toApplyCert();
          this.applydialogVisible = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          return false;
        }
        this.$router.push({
          path: '/gxPay',
          query: {
            ydId: 1,
            expressId: res.data.data.expressId
          }
        });
      });
    },
    //申请邮寄
    apply() {
      if (this.seletList.length == 0) {
        this.$message('请选择证书！');
        return false;
      }
      getUserAddressList({}).then(res => {
        console.log(res.data.data);
        this.ressList = res.data.data;
        this.applydialogVisible = true;
      });
    },
    //删除
    delet(id) {
      console.log(id);
      this.searcertList.forEach(item => {
        if (item.ID == this.certList[id].ID) {
          item.text = '添加';
          this.index = this.index + 1;
          this.certList.splice(id, 1);
        }
      });
    },
    //添加搜索的申领证书
    add(item) {
      this.certList.push(item);
      item.text = '已添加';
      console.log(this.certList);
      this.index = this.index + 1;
    },
    handleSelectionChange(rows) {
      console.log(rows);
      this.seletList = rows;
    },
    navBar(id) {
      this.navIndex = id;
    },
    //获取可申请证书列表
    toApplyCert() {
      toApplyCert({}).then(res => {
        console.log(res.data.data.certList);
        if (res.data.data.certList == undefined) {
          this.certList = []
        }else {
          this.certList = res.data.data.certList;
        }
        this.orderExpressList = res.data.data.orderExpressList;
        this.balance = res.data.data.balance;
        this.tmUrl = res.data.data.tmUrl;
        this.totalPrice = res.data.data.totalPrice;
      });
    },
    searCar() {
      if (this.cardno.length < 8) {
        this.$message('身份证格式不正确');
        return false;
      }
      toSearchByCardNo({ cardno: this.cardno }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '01') {
          this.searcertList = res.data.data.certList;
          this.searcertList.forEach(item => {
            item.text = '添加';
          });
        } else if (res.data.data.result == '01') {
          this.$message('身份证号不存在');
          return false;
        } else {
          this.$message('禁止搜索自己！');
          return false;
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toApplyCert();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.table1 {
  border-top: 1px solid #adb9c2;
  border-left: 1px solid #adb9c2;
  border: 0;
  background-color: #fff;
}
.td1 {
  height: 50px;
  background: #f7f7f7;
  text-align: center;
  padding-left: 0;
}
tr {
  height: 28px;
}
tr td {
  height: 50px;
  border: 1px solid #eee;
  padding-left: 10px;
  font-size: 12px;
  color: #333333;
}
.flex {
  display: flex;
}
.appLiBox {
  width: 988px;
  background: #fff;
  min-height: 678px;
  padding: 20px;
  border-radius: 6px;
  .navBox {
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    color: #2878ff;
  }
  .active {
    background: #2878ff !important;
    color: #fff !important;
  }

  .su {
    width: 4px;
    height: 18px;
    border-radius: 3px;
    background: #2878ff;
  }
}
</style>