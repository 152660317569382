<template>
  <div>
    <div class="couOrder"
         v-show="codeBox"
         style="display: block; position: relative">
      <img style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
           src="../../../assets/images/tips_bgc_bg.png"
           alt="" />
      <div style="font-size: 14px; color: #fff; position: relative; padding: 16px 54px; text-align: center; line-height: 24px">
        本平台是安徽省专业技术人员继续教育基地冠成教育旗下专业技术人员继续教育网络培训平台，提供专业课以及转岗培训在线学习服务,
        欢迎广大专业技术人员在本平台进行报名学习，专业课及转岗培训请按照各地市及行业主管部门要求进行报名学习！
      </div>
      <div style="font-size: 14px; color: #fff; position: relative; padding: 8px 30px; text-align: center; line-height: 30px">
        <div>
          <div style="margin-left: 20px; display: flex; flex-wrap: wrap">
            <div style="
                background: #fff;
                width: 89px;
                height: 28px;
                border: 1px solid #ffffff;
                text-align: center;
                line-height: 28px;
                font-size: 12px;
                color: #2878ff;
                margin-right: 10px;
                border-radius: 4px;
              ">
              专业课：
            </div>

            <div v-for="item in codeList"
                 :key="item.ID"
                 @click="goList(item)"
                 style="
                text-align: center;
                line-height: 28px;
                width: 89px;
                height: 28px;
                border: 1px solid #ffffff;
                border-radius: 4px;
                font-size: 12px;
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 15px;
                overflow: hidden;
              ">
              {{ item.CODE_NAME }}
            </div>
          </div>
        </div>
        <div>
          <!-- <div style="margin-left: 20px; display: flex; flex-wrap: wrap">
            <div style="
                background: #fff;
                width: 89px;
                height: 28px;
                border: 1px solid #ffffff;
                text-align: center;
                line-height: 28px;
                font-size: 12px;
                color: #2878ff;
                margin-right: 10px;
                border-radius: 4px;
              ">
              公需课：
            </div>
            <div @click="goGx"
                 style="
                padding: 0 5px;
                text-align: center;
                line-height: 28px;
                height: 28px;
                border: 1px solid #ffffff;
                border-radius: 4px;
                font-size: 12px;
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 15px;
              ">
              碳达峰碳中和与绿色发展
            </div>
          </div> -->
        </div>
      </div>
      <div style="width: 100%; height: 28px">
        <div style="width: 160px; height: 28px; position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 0)">
          <img style="position: absolute; top: 0; left: 0"
               src="../../../assets/images/ti.png"
               alt="" />
          <div style="text-align: center; line-height: 28px; cursor: pointer; position: relative; color: #2878ff"
               @click="showGo">
            点击收起
          </div>
        </div>
      </div>
    </div>
    <div class="couOrder"
         v-show="!codeBox">
      <img style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
           src="../../../assets/images/tips_bgc_bg.png"
           alt="" />
      <div style="font-size: 14px; color: #fff; position: relative; padding: 16px 54px; text-align: center; line-height: 24px">
        本平台是安徽省专业技术人员继续教育基地冠成教育旗下专业技术人员继续教育网络培训平台，提供公需课 ,专业课以及转岗培训在线学习服务,
        欢迎广大专业技术人员在本平台进行报名学习，专业课及转岗培训请按照各地市及行业主管部门要求进行报名学习！
      </div>
      <div style="width: 100%; height: 28px">
        <div style="width: 160px; height: 28px; position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 0)">
          <img style="position: absolute; top: 0; left: 0"
               src="../../../assets/images/ti.png"
               alt="" />
          <div @click="showGo"
               style="text-align: center; line-height: 28px; cursor: pointer; position: relative; color: #2878ff;font-size: 12px;">
            点击展开
          </div>
        </div>
      </div>
    </div>

    <div class="usuQst">
      <div style="font-size: 18px; color: #333; font-weight: bold">在学班级进度</div>

      <div class="mlist">
        <div class="studyList"
             v-if="studyClassList.length == 0"
             style="justify-content: center">暂无数据~</div>
        <div v-else
             v-for="item in studyClassList"
             :key="item.classId">
          <div class="studyList"
               v-if="item.isGxk == '02' ">
            <div>
              <img style="width: 110px; height: 90px; border-radius: 6px"
                   :src="item.classImage"
                   alt="" />
            </div>
            <div style="margin-left: 10px; width: 300px">
              <div style="margin-top: 10px; font-size: 16px; color: #333; font-weight: bold">{{ item.className }}</div>
              <div style="margin-top: 7px; font-size: 12px; color: #666">
                要求学时：<span>{{ item.classCreditHour }}学时 &emsp;</span>
              </div>
              <div style="margin-top: 7px; font-size: 12px; color: #666">
                完成学时：<span>{{ item.hasCredit >= item.classCreditHour ? item.classCreditHour : item.hasCredit }}</span>学时
              </div>
            </div>
            <div style="width: 200px; margin-top: 15px">
              <p style="
                width: 89px;
                height: 24px;
                background: #4f90ff;
                border-radius: 4px;
                text-align: center;
                line-height: 24px;
                margin: 0 auto;
              ">
                <span class=""
                      style="color: #fff"
                      v-if="item.classCreditHour > item.hasCredit">{{ Math.round((item.hasCredit / item.classCreditHour) * 100) }} %</span>
                <span class=""
                      style="color: #fff"
                      v-else>100%</span>
              </p>

              <el-progress :stroke-width="10"
                           style="width: 200px; margin-top: 10px"
                           :show-text="false"
                           :percentage="Math.round((item.hasCredit / item.classCreditHour) * 100)"></el-progress>
            </div>

            <div>
              <div class="studyBtn"
                   style=""
                   v-if="item.isGxk == '02' && item.isAllowStudy == '02' && item.isAllowStudyGxk == '02' "
                   @click="goA(item)">继续学习</div>
              <div class="studyBtn"
                   v-else
                   @click="noGo(item)">
                继续学习
              </div>
              <div class="studyBtn"
                   @click="goExam"
                   style="margin-top: 5px;"
                   v-if="item.isExam == '02'">参加考试</div>
            </div>
          </div>
          <div class="studyList"
               v-else>
            <div>
              <img v-if="item.isGxk !== '02' && item.classImage && item.classImage !==null && item.classImage !== ''"
                   style="width: 110px; height: 90px; border-radius: 6px"
                   :src="item.classImage"
                   alt=""
                   @error="handleError">
              <div class="boxImg"
                   v-else
                   style="position: relative;display: flex;justify-content: center;align-items: center;width: 110px; height: 90px; border-radius: 6px;overflow: hidden;">
                <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 1;"
                     src="../../../assets/indexImg/noimg.png"
                     alt="">
                <div style="position: relative;z-index: 2;;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                  {{  item.className }}
                </div>
              </div>
            </div>
            <div style="margin-left: 10px; width: 300px">
              <div style="margin-top: 10px; font-size: 16px; color: #333; font-weight: bold">{{ item.className }}</div>
              <div style="margin-top: 7px; font-size: 12px; color: #666">
                要求学时：<span>{{ item.classCreditHour }}学时 &emsp;</span>
              </div>
              <div style="margin-top: 7px; font-size: 12px; color: #666">
                完成学时：<span>{{ item.hasCredit >= item.classCreditHour ? item.classCreditHour : item.hasCredit }}</span>学时
              </div>
            </div>
            <div style="width: 200px; margin-top: 15px">
              <p style="
                width: 89px;
                height: 24px;
                background: #4f90ff;
                border-radius: 4px;
                text-align: center;
                line-height: 24px;
                margin: 0 auto;
              "
                 v-if="item.classCreditHour !== 0">
                <span v-if="(item.hasCredit / item.classCreditHour) >= 1"
                      style="color: #fff">100%</span>

                <span v-else
                      style="color: #fff">{{ Math.round((item.hasCredit / item.classCreditHour) * 100)  }}%</span>

              </p>

              <el-progress v-if="item.classCreditHour !== 0"
                           :stroke-width="10"
                           style="width: 200px; margin-top: 10px"
                           :show-text="false"
                           :percentage="(item.hasCredit / item.classCreditHour) * 100"></el-progress>
            </div>
            <div>
              <div class="studyBtn"
                   style=""
                   @click="goA(item)">继续学习</div>

              <div class="studyBtn"
                   v-if="item.isGxk !== '02'"
                   style="margin-top: 5px;"
                   @click="goxs(item)">学时证明</div>
              <div class="studyBtn"
                   @click="goExam"
                   style="margin-top: 5px;"
                   v-if="item.isExam == '02'">参加考试</div>
            </div>
          </div>

        </div>

        <div @click="goStudy"
             v-if="studyClassList.length !== 0"
             style="text-align: center;color: #2878ff;font-size: 16px;margin-top: 20px;cursor: pointer;">
          点击查看更多
        </div>
      </div>

    </div>

    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="20%"
               :show-close="false"
               center
               :close-on-click-modal="false"
               :close-on-press-escape="false">
      <div style="text-align: center;">您还未完善个人信息，请先去完善后再来学习</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="goUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toMyClassList, getCodeByParent } from '@/api/study.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      showFalg: false,
      queryParams: {},
      total: 0,
      studyClassList: [], //班级数据
      codeBox: false, //显示隐藏
      codeList: [], //分类数据
      userInfo: {},
      urlInfo: {}, //跳转的链接
      defaultImageUrl: require('../../../assets/images/noimg.png'),
      dialogVisible: false,
      isMember: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

    goxs(item) {
      this.$router.push({
        path:'/xszm',
        query:{
          classId:item.classId,
          userId:this.urlInfo.userId
        }
      });
    },
    goStudy() {
      window.location.href = this.urlInfo.studyUrl + '&token=' + localStorage.getItem('token');
    },
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    noGo(item) {
      if (item.isAllowStudy == '01' && item.isAllowStudyGxk == '01') {
        this.$message('公需课平台维护，暂时不能学习！');
      } else if (item.isAllowStudyGxk == '01') {
        this.$message('此公需课程已结束，不允许学习和考试！');
        return false;
      } else if (item.isAllowStudy == '01') {
        this.$message('公需课平台维护，暂时不能学习！');
      }
    },
    //
    goUser() {
      this.$emit('goUser');
      this.$notify.closeAll();
    },
    goExam() {
      window.location.href = this.urlInfo.quizUrl + '&token=' + localStorage.getItem('token');
    },
    goA(item) {
      window.location.href =
        this.urlInfo.studyUrl + '&token=' + localStorage.getItem('token') + '&classId=' + item.classId + '&className=' + item.className + '&orderId=' + item.orderId;
    },
    goList(item) {
      console.log(item);
      this.$router.push({
        path: '/lsitFs',
        query: {
          codeNo: item.CODE_NO, //第一级
          fCodeNo: '0702'
        }
      });
    },
    //去公需课
    goGx() {
      this.$router.push('/gx');
    },
    showGo() {
      this.codeBox = !this.codeBox;
    },
    getList() {
      toMyClassList(this.queryParams).then(res => {
        console.log(res.data);
        this.urlInfo = res.data.data;
        this.isMember = res.data.data.isMember;
        this.$emit('isMember', this.isMember);
        if (this.urlInfo.ordNotPayCount && this.urlInfo.ordNotPayCount == '1') {
          this.$notify.closeAll();
          this.$notify({
            title: '您有1笔未支付订单',
            message: '点击前往',
            position: 'bottom-right',
            duration: 0,
            onClick: () => {
              console.log(1);
              this.$emit('eventName');
              this.$notify.closeAll();
            }
          });
        }
        if (this.urlInfo.requiredFlag == '01') {
          this.dialogVisible = true;
        }

        this.$emit('childEvent2', this.urlInfo);
        if (res.data.data.studyClassList) {
          this.studyClassList = res.data.data.studyClassList;
        }
      });
      getCodeByParent({}).then(res => {
        this.codeList = res.data.data.codeList;
        this.userInfo.realname = res.data.data.realname;
        this.userInfo.userphoto = res.data.data.userphoto;
        this.userInfo.balance = res.data.data.balance;
        this.$emit('childEvent', this.userInfo);
        console.log(res.data.data);
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #ececec;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.couOrder {
  margin-bottom: 12px;
  width: 988px;
  min-height: 110px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
}
.usuQst {
  width: 988px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  min-height: 625px;
  .mlist {
    .studyList {
      display: flex;
      padding-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
      padding-top: 20px;
      box-sizing: border-box;
      .studyBtn {
        margin-left: 200px;
        width: 101px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 6px;
        font-size: 12px;
        text-align: center;
        line-height: 34px;
        color: #666;
        cursor: pointer;
      }
    }
  }
}
</style>
