<template>
  <div>
    <div class="classBnaer">
      <div class="classHead">
        <div class="leftBox">

        </div>

        <div>
          <el-input type="text"
                    size="small"
                    v-model="queryParams.certNo"
                    style="margin-right: 5px;width:150px;"
                    placeholder="请输入证书编号"
                    clearable></el-input>
          <el-select size="small"
                     v-model="queryParams.certType"
                     style="width: 150px;margin-right: 5px;"
                     placeholder="请选择证书类型"
                     clearable>
            <el-option v-for="item in certNoOptions"
                       :key="item.ID"
                       :label="item.CODE_NAME"
                       :value="item.CODE_NO">
            </el-option>
          </el-select>
          <el-date-picker size="small"
                          style="width: 150px;margin-right: 5px;"
                          type="year"
                          v-model="queryParams.year"
                          placeholder="请选择年份"
                          value-format="yyyy"
                          clearable>
          </el-date-picker>
          <el-select size="small"
                     style="width: 150px;margin-right: 5px;"
                     v-model="queryParams.receiveType"
                     placeholder="请选择领取类型"
                     clearable>
            <el-option v-for="item in receiveTypeOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

          <el-button icon="el-icon-search"
                     size="small"
                     type="primary"
                     @click="toCertList">
            搜索
          </el-button>
        </div>
      </div>

      <div class="list">
        <el-table :data="certList"
                  style="width: 100%;">
          <el-table-column prop="CERT_NAME"
                           label="证书名称"
                           width="180"
                           align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.CERT_TYPE == '0700'"
                   style="color: #2878FF;cursor: pointer;">
                {{ scope.row.CERT_NAME }}
              </div>
              <div v-else>
                {{ scope.row.CERT_NAME }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CERT_NO"
                           label="证书编号"
                           width="180"
                           align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.CERT_TYPE == '0700'"
                   >
                -
              </div>
              <div v-else>
                {{ scope.row.CERT_NO }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address"
                           label="类型	"
                           align="center">
            <template slot-scope="scope">
              <span style="color: #4077C0;">{{ scope.row.CERT_TYPE_NAME }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="YEAR"
                           label="年度"
                           align="center">
          </el-table-column>
          <el-table-column prop="CREDIT"
                           label="完成学时"
                           align="center">
            <template slot-scope="scope">
                {{Math.ceil(scope.row.CREDIT) || ''}}
            </template>
          </el-table-column>
          <el-table-column prop="PRINT_FLAG"
                           label="打印状态"
                           align="center">
            <template slot-scope="scope">
              <span style="color: #4077C0;" v-if="scope.row.CERT_TYPE !== '0700'">{{ scope.row.PRINT_FLAG == '01' ? '未打印' :'已打印' }}</span>
              <span style="color: #4077C0;" v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="PRINT_FLAG"
                           label="领取状态"
                           align="center">
            <template slot-scope="scope">
              <span style="color: #4077C0;" v-if="scope.row.CERT_TYPE !== '0700'">{{ scope.row.PRINT_FLAG == '01' ? '未领取' :'已领取' }}</span>
              <span style="color: #4077C0;" v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="RECEIVE_TYPE"
                           label="领取方式	"
                           align="center">
            <template slot-scope="scope">
              <span style="color: #4077C0;" v-if="scope.row.CERT_TYPE !== '0700'">{{ scope.row.RECEIVE_TYPE == '01' ? '自领' : scope.row.RECEIVE_TYPE == '02' ? '代领' : scope.row.RECEIVE_TYPE == '00' ? '未领取' : '' }}</span>
              <span style="color: #4077C0;" v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template slot-scope="scope">
              <router-link v-if="scope.row.CERT_TYPE !== '0700'"
                           style="color: #2878FF;cursor: pointer;"
                           :to="{path:'/cert',query:{id:scope.row.ID}}"
                           target="_blank">证书查看</router-link>
              <div v-else
                   style="color: #2878FF;cursor: pointer;"
                   @click="gox(scope.row)">证书查看</div>
            </template>

          </el-table-column>
        </el-table>

      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toCertList, toGetCertType } from '@/api/study.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      certList: [], //证书列表
      certNoOptions: [],
      receiveTypeOptions: [
        {
          label: '自领',
          value: '01'
        },
        {
          label: '代领',
          value: '02'
        },
        {
          label: '邮寄',
          value: '03'
        },
        {
          label: '现场发放',
          value: '04'
        },
        {
          label: '批量邮寄',
          value: '05'
        }
      ]
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //公需课证书
    gox(row) {
      this.$confirm('公需课证书需要跳转到公需课平台查看，是否跳转?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          window.open(row.tmUrl);
        })
        .catch(() => {});
    },
    toCertList() {
      console.log(this.queryParams.year);
      toCertList(this.queryParams).then(res => {
        this.certList = res.data.rows;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toCertList();
    toGetCertType().then(res => {
      this.certNoOptions = res.data.data;
    });
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */

.classBnaer {
  width: 988px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-family: Regular;
  .classHead {
    display: flex;
    justify-content: space-between;
    .leftBox {
    }
  }
  .list {
    .m4 {
      margin-top: 4px;
    }
    .s2mDetail {
      overflow: hidden;
      padding-top: 30px;
      min-height: 153px;
      padding-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      .s2mBg {
        width: 110px;
        height: 90px;
        border-radius: 6px;
      }

      .s2mProd {
        width: 400px;
        margin-left: 10px;
      }

      .rightBox {
        .rightBtn {
          width: 100px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #666666;
          opacity: 1;
          border-radius: 6px;
          font-size: 14px;
          color: #666666;
          text-align: center;
          line-height: 34px;
          margin-bottom: 5px;
          cursor: pointer;
        }
        .xueke {
          border: 1px solid #2878ff !important;
          color: #2878ff !important;
        }
      }
    }
  }
}
</style>