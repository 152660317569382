<template>
  <div>
    <div class="classBnaer">
      <div class="classHead">
        <div class="leftBox">温馨提示：学员可在此查看和打印发票</div>

        <div>
          <el-input
            size="small"
            style="width: 205px; margin-right: 5px"
            placeholder="请输入订单编号"
            v-model="queryParams.orderNo"
            :clearable="true"
          ></el-input>
          <el-input
            size="small"
            style="width: 145px; margin-right: 5px"
            placeholder="请输入班级名称"
            v-model="queryParams.className"
            :clearable="true"
          ></el-input>
          <el-select
            size="small"
            style="width: 145px; margin-right: 5px"
            placeholder="请选择订单状态"
            v-model="queryParams.orderStatus"
            :clearable="true"
          >
            <el-option label="未支付" value="01"> </el-option>
            <el-option label="已支付" value="20"> </el-option>
            <el-option label="已取消" value="00"> </el-option>
          </el-select>

          <el-button icon="el-icon-search" size="small" type="primary" @click="searOrder"> 搜索 </el-button>
        </div>
      </div>

      <div class="list">
        <el-table :data="orderList.list" style="width: 100%">
          <el-table-column prop="ORD_NO" label="订单编号" width="180" align="center"> </el-table-column>
          <el-table-column prop="CREATETIME" label="时间" width="180" align="center"> </el-table-column>
          <el-table-column prop="className" label="班级名称" align="center"> </el-table-column>
          <el-table-column prop="INVOICE_CLAIM_TYPE" label="生成发票" align="center">
            <template slot-scope="scope">
              <span style="color: #4077c0">{{ scope.row.INVOICE_CLAIM_TYPE == '01' ? '否' : '是' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ORD_MONEYSUM" label="金额" align="center" width="180">
            <template slot-scope="scope">
              <div>总额：￥{{ scope.row.ORD_TOTAL_MONEY.toFixed(2) }}</div>
              <div v-if="scope.row.ORD_TYPE !== '03'">应付：￥{{ scope.row.ORD_MONEYSUM.toFixed(2) }}</div>
              <div v-else>应付：￥{{ scope.row.ORD_TOTAL_MONEY.toFixed(2) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ORD_STATUS" label="支付状态" align="center">
            <template slot-scope="scope">
              <span style="color: #4077c0" v-if="scope.row.ORD_STATUS == '01' || scope.row.ORD_STATUS == '02'">
                <div style="text-align: center">未支付</div>
                <div v-if="scope.row.surplusTime !== ''">{{ hours }}:{{ minutes }}:{{ seconds }}</div>
              </span>
              <span style="color: #4077c0" v-else>
                {{
                  scope.row.ORD_STATUS == '20'
                    ? '已支付'
                    : scope.row.ORD_STATUS == '00'
                    ? '已取消'
                    : scope.row.ORD_STATUS == '10' || scope.row.ORD_STATUS == '11' || scope.row.ORD_STATUS == '12'
                    ? ''
                    : '未支付'
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="center">
            <template slot-scope="scope">
              <div
                class="btn"
                @click="invoice(scope.row)"
                v-if="
                  (scope.row.ORD_TYPE == '01' &&
                    scope.row.INVOICE_CLAIM_TYPE !== '02' &&
                    scope.row.ORD_STATUS == '20' &&
                    scope.row.ORD_MONEYSUM > 0) ||
                  (scope.row.ORD_TYPE == '04' &&
                    scope.row.INVOICE_CLAIM_TYPE !== '02' &&
                    scope.row.ORD_STATUS == '20' &&
                    scope.row.ORD_MONEYSUM > 0) ||
                  (scope.row.ORD_TYPE == '05' &&
                    scope.row.INVOICE_CLAIM_TYPE !== '02' &&
                    scope.row.ORD_STATUS == '20' &&
                    scope.row.ORD_MONEYSUM > 0)
                "
              >
                申请开票
              </div>
              <div v-else>
                <div v-if="scope.row.invoice">
                  <div v-if="scope.row.invoice.invoiceState == '07'">开票中</div>
                  <div
                    v-if="scope.row.invoice.invoiceState == '08'"
                    style="color: rgb(64, 119, 192); cursor: pointer"
                    @click="downFp(scope.row.invoice.invoicePdf)"
                  >
                    查看发票
                  </div>
                </div>
              </div>
              <div
                class="btn"
                style="color: #b93b3b; border: 1px solid #b93b3b ;"
                @click="payment(scope.row)"
                v-if="(scope.row.ORD_STATUS == '01' || scope.row.ORD_STATUS == '02') && scope.row.ORD_TYPE !== '03'"
              >
                支付
              </div>
              <div style="display: flex; justify-content: center">
                <div
                  v-if="(scope.row.ORD_STATUS == '01' || scope.row.ORD_STATUS == '02') && scope.row.ORD_TYPE !== '03'"
                  style="cursor: pointer"
                  @click="quOrder(scope.row)"
                >
                  取消
                </div>

                <div v-if="scope.row.ORD_STATUS == '00'" style="color: #b93b3b; cursor: pointer" @click="deletOrder(scope.row)">删除</div>
                <div style="margin-left: 5px; cursor: pointer" @click="showDetil(scope.row)">详情</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 订单详情 -->
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="80%">
      <div class="check_box" style="display: flex">
        <div style="width: 49%">
          <table class="table1">
            <tbody v-for="(item, idx) in orderOutList" :key="idx">
              <tr>
                <td width="30%" class="td1">订单号</td>
                <td width="60%" id="eordNo" class="td2">{{ item.ordNo }}</td>
              </tr>
              <tr>
                <td class="td1">订单金额</td>
                <td id="ordTotalMoney" class="td2">￥{{ item.ordTotalMoney }}</td>
              </tr>
              <tr>
                <td class="td1">支付金额</td>
                <td id="ordMoneysum" class="td2" v-if="item.ordType !== '03'">￥{{ item.ordMoneysum }}</td>
                <td id="ordMoneysum" class="td2" v-else>￥{{ item.ordTotalMoney }}</td>
              </tr>
              <tr>
                <td class="td1">支付方式</td>
                <td id="ordPaytype" class="td2">
                  {{
                    item.ordPaytype == '01'
                      ? '在线支付'
                      : item.ordPaytype == '02'
                      ? '学习帐户'
                      : item.ordPaytype == '03'
                      ? '现金面付'
                      : item.ordPaytype == '04'
                      ? '银行汇款'
                      : item.ordPaytype == '05'
                      ? '邮局汇款'
                      : item.ordPaytype == '06'
                      ? '支付宝'
                      : item.ordPaytype == '07'
                      ? '手机支付(支付宝)'
                      : item.ordPaytype == '08'
                      ? '刷卡支付'
                      : item.ordPaytype == '00'
                      ? '其它'
                      : item.ordPaytype == '0901'
                      ? '微信公众号'
                      : item.ordPaytype == '0902'
                      ? '微信WAP'
                      : item.ordPaytype == '0903'
                      ? '在线支付'
                      : item.ordPaytype == '13'
                      ? '未到账'
                      : item.ordPaytype == '0904'
                      ? '微信APP'
                      : item.ordPaytype == '10'
                      ? '手工'
                      : item.ordPaytype == '11'
                      ? '银联支付'
                      : item.ordPaytype == '12'
                      ? '手机银联'
                      : item.ordPaytype == '14'
                      ? '学习卡'
                      : ''
                  }}
                </td>
              </tr>
              <tr>
                <td class="td1">支付时间</td>
                <td id="ordPaytime" class="td2">{{ item.ordPaytime }}</td>
              </tr>
              <tr>
                <td class="td1">支付渠道</td>
                <td id="ordPaySource" class="td2">
                  {{
                    item.ordPaySource == '01'
                      ? '电脑web'
                      : item.ordPaySource == '02'
                      ? '手机网站'
                      : item.ordPaySource == '03'
                      ? '手机app'
                      : item.ordPaySource == '04'
                      ? '公众号'
                      : ''
                  }}
                </td>
              </tr>
              <tr>
                <td class="td1">减免清单</td>
                <td id="jmqd" class="td2">
                  <span v-if="item.ordAccountMoney !== 0"> 减免金额:{{ item.ordAccountMoney }}元，减免方式:学习账户 </span>
                  <span v-if="item.ordIntegralMoney !== 0"> 减免金额:{{ item.ordIntegralMoney }}元，减免方式:积分抵用 </span>
                </td>
              </tr>
              <tr>
                <td class="td1">支付状态</td>
                <td id="ordStatus" class="td2">
                  {{
                    item.ordStatus == '01'
                      ? '未支付'
                      : item.ordStatus == '00'
                      ? '已取消'
                      : item.ordStatus == '02'
                      ? '支付中'
                      : item.ordStatus == '20'
                      ? '已支付'
                      : ''
                  }}
                </td>
              </tr>
              <tr>
                <td class="td1">订单类型</td>
                <td id="ordType" class="td2">
                  {{ item.ordType == '01' ? '个人' : item.ordType == '02' ? '集体' : item.ordType == '03' ? '机构' : '' }}
                </td>
              </tr>
              <tr>
                <td class="td1">生成发票</td>
                <td id="invoiceClainType" class="td2">
                  {{ item.invoiceClainType == '01' ? '否' : '是' }}
                </td>
              </tr>
              <tr>
                <td class="td1">发票批次</td>
                <td id="fppc" class="td2">
                  <span v-if="item.invoiceBatch !== null">
                    {{ item.invoiceBatch }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 49%">
          <table class="table2" id="classDetail" style="margin-left: 20px">
            <!--固定-->
            <tbody>
              <tr style="height: 50px; background: #f7f7f7">
                <td width="33%" style="text-align: center">报名班级</td>
                <td width="33%" style="text-align: center; padding: 0">课程/考试/练习</td>
              </tr>

              <!--动态-->
              <tr class="tr_name" v-for="(item, index) in eOrderDetailList" :key="index" height="50">
                <td>{{ item.className }}</td>
                <td>{{ item.courseName ? item.courseName : item.quizName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 开票 -->
    <el-dialog :visible.sync="invoiceShow" width="500px">
      <div style="border: 0; width: 100%; margin: 0 auto" align="center">
        <div style="display: flex">
          <div style="line-height: 40px; font-size: 16px; color: #2878ff">发票抬头</div>
          <el-input style="margin-left: 41px; width: 350px" type="text" v-model="invoices.invoiceHeader" placeholder="请填写发票抬头" />
        </div>
        <div></div>
        <div style="display: flex; margin-top: 20px">
          <div style="line-height: 40px; font-size: 16px; color: #2878ff">纳税人识别号</div>
          <el-input
            style="margin-left: 10px; width: 350px"
            type="text"
            v-model="invoices.identifyNum"
            placeholder="请填写纳税人识别号或统一社会信用代码"
          />
        </div>
        <div style="padding-left: 0; text-align: center; margin-top: 20px; font-size: 14px; color: #999999; word-wrap: ">
          <div>
            填写要求请见<a style="color: #ff8000" target="_blank" href="http://www.chinatax.gov.cn/n810341/n810755/c2644618/content.html"
              >《国家税务总局关于增值税发票开具有关问题的公告》</a
            >
          </div>
        </div>
        <p style="padding-left: 0; text-align: center; margin-top: 5px; font-size: 14px; color: #999999">
          开具增值税普通发票，请于缴费当年领取，不支持跨年度领取
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="invoiceShow = false">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import { toOrderList, doDeleteOrder, doCancelOrder, toOrderDetail, doGenerateInvoice } from '@/api/study.js';
import { toGenerateOrder } from '@/api/pay.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      dialogVisible: false, //订单详情
      invoiceShow: false, //申请开票
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      orderList: [], //订单列表
      independentExamList: [], //独立考试
      eOrderDetailList: [], //课程内容
      orderOutList: [], //订单内容
      orderId: '', //申请开票id
      invoices: {
        invoiceHeader: '',
        identifyNum: ''
      },
      falg: true,
      timerId: null,
      totalSeconds: 0
    };
  },
  // 监听属性 类似于data概念
  computed: {
    hours() {
      const h = Math.floor(this.totalSeconds / (60 * 60));
      if (h >= 24) {
        this.stopCountdown();
      }
      return h;
    },
    minutes() {
      const m = Math.floor((this.totalSeconds % (60 * 60)) / 60);
      return m > 59 ? '59' : m;
    },
    seconds() {
      const s = Math.floor(this.totalSeconds % 60);
      return s > 59 ? '59' : s;
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //下载发票
    downFp(url) {
      window.open(url);
    },
    searOrder() {
      this.stopCountdown();
      toOrderList(this.queryParams).then(res => {
        this.orderList = res.data.data;
        this.orderList.list.forEach(item => {
          if (item.surplusTime && item.surplusTime !== '' && item.ORD_STATUS == '01') {
            let hour = item.surplusTime.substring(0, 2);
            let min = item.surplusTime.substring(3, 5);
            let s = item.surplusTime.substring(6, 8);
            this.totalSeconds = Number(hour) * 60 * 60 + Number(min) * 60 + Number(s);

            this.startCountdown();
          }
        });
      });
    },
    //支付
    payment(row) {
      //判断订单是不是公需课
      if (row.isGxk == '02' && this.orderList.isAllowEnrollGxk == '02') {
        this.$router.push({
          path: '/gxPay',
          query: {
            orderId: row.ID
          }
        });
        return false;
      } else if (row.isGxk == '02' && this.orderList.isAllowEnrollGxk == '01') {
        this.$message('安徽省专业技术人员继续教育管理平台维护中，暂时不可报名公需课!');
        return false;
      }
      this.$router.push({
        path: '/toPayOrder',
        query: {
          orderId: row.ID
        }
      });
    },
    //取消
    quOrder(row) {
      this.$confirm('是否确认取消本条订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          doCancelOrder({ orderId: row.ID }).then(res => {
            if (res.data.code == '200') {
              this.$message({
                type: 'success',
                message: '取消成功!'
              });
            }
            this.toOrderList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    //删除订单
    deletOrder(row) {
      this.$confirm('是否确认删除本条订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          doDeleteOrder({ orderId: row.ID }).then(res => {
            if (res.data.code == '200') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.toOrderList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    showDetil(row) {
      toOrderDetail({ orderId: row.ID }).then(res => {
        console.log(res.data.data);
        this.independentExamList = res.data.data.independentExamList;
        this.eOrderDetailList = res.data.data.eOrderDetailList;
        this.orderOutList = res.data.data.orderOutList;
      });
      this.dialogVisible = true;
    },
    invoice(row) {
      this.orderId = row.ID;
      this.invoiceShow = true;
      this.falg = true;
      this.invoices.invoiceHeader = '';
      this.invoices.identifyNum = '';
    },
    //确定申请发票
    submitInfo() {
      if (this.invoices.invoiceHeader == '' || this.invoices.identifyNum == '') {
        this.$message('请输入发票信息');

        return false;
      }

      if (this.invoices.identifyNum.length == 18) {
        let ptn = /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}/;
        let ma = ptn.test(this.invoices.identifyNum);
        if (!ma) {
          this.$message('纳税人识别号不符合规则!');
          return;
        }
      } else if (this.invoices.identifyNum.length == 15) {
        let ptn = /^[0-9a-zA-Z]{15}$/;
        let ma = ptn.test(this.invoices.identifyNum);
        if (!ma) {
          this.$message('纳税人识别号不符合规则!');
          return;
        }
      } else if (this.invoices.identifyNum.length == 20) {
        let ptn = /^[0-9]{6}[0-9a-zA-Z]{9}[0-9]{5}$/;
        let ma = ptn.test(this.invoices.identifyNum);
        if (!ma) {
          this.$message('纳税人识别号不符合规则!');
          return;
        }
      } else {
        this.$message('纳税人识别号不符合规则!');
        return;
      }
      if (!this.falg) {
        return this.falg;
      }
      this.falg = false;
      let data = {
        invoiceHeader: this.invoices.invoiceHeader,
        identifyNum: this.invoices.identifyNum,
        orderId: this.orderId
      };
      doGenerateInvoice(data).then(res => {
        console.log();
        if (res.data.code == '200') {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.invoiceShow = false;
          this.toOrderList();
        }
      });
    },
    toOrderList() {
      toOrderList({}).then(res => {
        this.stopCountdown();
        this.orderList = res.data.data;
        this.orderList.list.forEach(item => {
          if (item.surplusTime && item.surplusTime !== '' && item.ORD_STATUS == '01') {
            let hour = item.surplusTime.substring(0, 2);
            let min = item.surplusTime.substring(3, 5);
            let s = item.surplusTime.substring(6, 8);
            console.log(hour, min, s);
            this.totalSeconds = Number(hour) * 60 * 60 + Number(min) * 60 + Number(s);
            this.startCountdown();
          }
        });
      });
    },
    startCountdown() {
      this.timerId = setInterval(() => {
        --this.totalSeconds;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timerId);
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.toOrderList();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.check_box {
  max-height: 541px;
  overflow-y: scroll;
  table {
    width: 100%;
    tr {
      height: 28px;
      td {
        height: 50px;
        border: 1px solid #eee;
        padding-left: 10px;
        font-size: 12px;
        color: #333333;
      }
    }
    .td1 {
      height: 50px;
      background: #f7f7f7;
      text-align: center;
      padding-left: 0;
    }
  }
}
.classBnaer {
  width: 988px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  .classHead {
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 280px;
      height: 36px;
      background: rgba(255, 128, 0, 0.1);
      border-radius: 6px;
      color: #ff8000;
      font-size: 14px;
      text-align: center;
      line-height: 36px;
    }
  }
  .list {
    margin-top: 15px;
    .btn {
      cursor: pointer;
      width: 70px;
      height: 30px;
      border: 1px solid #2878ff;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      margin: 0 auto;
      color: #2878ff;
    }
  }
}
</style>
