<template>
  <div>
    <div class="banner1">
      <div class="headNav">
        <div style="display: flex">
          <div :class="navIndex == 1 ? 'navBox navActive' : 'navBox'" @click="navBar(1)">个人资料</div>
          <div :class="navIndex == 2 ? 'navBox navActive' : 'navBox'" @click="navBar(2)">修改手机</div>
          <div :class="navIndex == 3 ? 'navBox navActive' : 'navBox'" @click="navBar(3)">修改密码</div>
          <div :class="navIndex == 4 ? 'navBox navActive' : 'navBox'" @click="navBar(4)">寄送地址</div>
        </div>

        <div class="tipsRight">* 为必填选项</div>
      </div>

      <div class="tabBox" v-if="navIndex == 1">
        <div style="font-weight: bold">上传头像</div>

        <div style="display: flex; margin-top: 15px; padding-bottom: 45px; border-bottom: 1px solid #ececed">
          <div class="updataImg">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :headers="headers"
            >
              <img v-if="userInfo.userphoto !== null" :src="userInfo.userphoto" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>

          <div style="margin-left: 20px; font-size: 14px; color: #666666">
            <p>照片要求<span style="color: #ff8000"></span></p>
            <p style="margin-top: 8px">1、一寸白底标准证件照（3.5*2.5cm）</p>
            <p style="margin-top: 4px">2、JPG、JPEG、PNG格式</p>
            <p style="margin-top: 4px">3、文件小于2M，推荐413*295像素</p>
          </div>
          <div style="margin-left: 90px; margin-top: 80px">
            <el-button class="submitBtn" style="background: #2878ff; color: #fff" @click="submitFormPhoto"> 确认保存 </el-button>
          </div>
        </div>

        <div class="userInfo">
          <el-form ref="form" :rules="rules" :model="userInfo" label-width="80px">
            <div style="font-weight: bold; margin-bottom: 20px">身份信息</div>
            <div style="display: flex">
              <div>
                <el-form-item label="用户名" prop="userName">
                  <el-input class="in" v-model="userInfo.userName" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="cardno">
                  <el-input class="in" v-model="userInfo.cardno" :disabled="true"></el-input>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="姓名" prop="realname">
                  <el-input class="in" v-model="userInfo.realname" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                  <el-select class="in" v-model="userInfo.sex" placeholder="请选择性别">
                    <el-option label="男" value="01"></el-option>
                    <el-option label="女" value="02"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div style="font-weight: bold; margin-bottom: 20px">联系方式</div>
            <div style="display: flex">
              <div>
                <el-form-item label="手机号码" prop="bindMobile">
                  <el-input class="in" v-model="userInfo.bindMobile" placeholder="请输入手机号码" :disabled="bindMobileShow"></el-input>
                </el-form-item>

                <div style="display: flex" v-if="!bindMobileShow">
                  <el-form-item label="验证码" prop="bindMobile">
                    <el-input class="in" v-model="userInfo.imgCode" placeholder="图片验证码" style="width: 110px"></el-input>
                  </el-form-item>
                  <img @click="getImgCode" class="code" :src="codeImg" alt="" />
                </div>
                <el-form-item label="电子邮箱">
                  <el-input class="in" v-model="userInfo.email" placeholder="请输入电子邮箱"></el-input>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="联系电话" label-width="120px">
                  <el-input class="in" v-model="userInfo.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <div style="display: flex" v-if="!bindMobileShow">
                  <el-form-item label="手机验证码" label-width="120px">
                    <el-input class="in" v-model="userInfo.yzm" placeholder="请输入手机验证码"></el-input>
                  </el-form-item>
                  <div class="codeBtn" @click="getCode2" v-if="!isCounting">{{ buttonText }}</div>
                  <div class="codeBtn" v-if="isCounting">{{ countdown }}s后重新发送</div>
                </div>
                <el-form-item label="联系地址" label-width="120px">
                  <el-input style="width: 400px" v-model="userInfo.userAddress" placeholder="请输入联系地址"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="font-weight: bold; margin-bottom: 20px">工作单位</div>
            <div style="display: flex">
              <div>
                <el-form-item label="单位名称" prop="organization">
                  <el-input class="in" v-model="userInfo.organization" placeholder="请输入单位名称"></el-input>
                </el-form-item>
              </div>

              <div style="margin-left: 20px">
                <el-form-item label="所在地区" prop="area">
                  <el-cascader
                    v-model="userInfo.area"
                    :options="optionsArea"
                    :props="props"
                    clearable
                    style="width: 280px"
                    placeholder="请选择所在地区"
                  ></el-cascader>
                </el-form-item>
              </div>
            </div>

            <div>
              <el-form-item label="单位地址" prop="address">
                <el-input class="in" v-model="userInfo.address" style="width: 580px" placeholder="请输入单位地址"></el-input>
              </el-form-item>
            </div>
            <!-- <div style="font-weight: bold; margin-bottom: 20px">专业水平</div>
            <div style="display: flex">
              <div>
                <el-form-item label="职称"
                              prop="title">
                  <el-cascader v-model="userInfo.title"
                               :options="zcList"
                               :props="props1"
                               style="width: 290px;"
                               placeholder="请选择职称"></el-cascader>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="学历">
                  <el-select class="in"
                             v-model="userInfo.degree"
                             placeholder="请选择学历">
                    <el-option label="大专以下"
                               value="01"></el-option>
                    <el-option label="大专"
                               value="02"></el-option>
                    <el-option label="本科"
                               value="03"></el-option>
                    <el-option label="硕士"
                               value="04"></el-option>
                    <el-option label="博士"
                               value="05"></el-option>
                    <el-option label="其他"
                               value="09"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div> -->
          </el-form>
          <div style="display: flex; justify-content: center">
            <el-button class="submitBtn" @click="submitForm('form')"> 确认保存 </el-button>
          </div>
        </div>
      </div>

      <div class="tabBox" v-if="navIndex == 2">
        <div class="phone">
          <div v-if="!phoneNext">
            <div style="font-size: 16px; color: #333333; text-align: center">第一步：安全验证</div>
            <div class="xgsj1_old_code">
              <input
                type="text"
                class="codeBox"
                style="width: 300px"
                v-model="userInfo.bindMobile"
                :disabled="true"
                placeholder="请输入手机号码"
              />
            </div>
            <div class="xgsj1_old_code" style="display: flex; align-items: center">
              <input type="text" placeholder="请输入验证码" v-model="codeQuerty.mobileCode" class="codeBox" style="width: 150px" />

              <el-button class="codeBtn" @click="getCode" style="line-height: 20px; width: 130px" v-if="!isCounting">
                {{ buttonText }}
              </el-button>
              <el-button class="codeBtn" v-if="isCounting" style="line-height: 20px; width: 130px"> {{ countdown }}s后重新发送 </el-button>
            </div>

            <div style="display: flex; justify-content: center">
              <el-button class="nax" @click="checkCode"> 下一步 </el-button>
            </div>
          </div>

          <div v-if="phoneNext">
            <div style="font-size: 16px; color: #333333; text-align: center">第二步：验证手机号</div>
            <input type="text" class="codeBox" style="width: 300px" v-model="codeQuerty.bindMobile" placeholder="请输入新手机号码" />
            <div class="xgsj1_old_code" style="display: flex">
              <input type="text" v-model="codeQuerty.imgCode" placeholder="请输入验证码" class="codeBox" />

              <div @click="getImgCode">
                <img class="codeBtn" style="margin-top: 15px" :src="codeImg" />
              </div>
            </div>

            <div class="xgsj1_old_code">
              <input type="text" placeholder="请输入验证码" v-model="codeQuerty.mobileCode" class="codeBox" style="width: 150px" />

              <el-button class="codeBtn" @click="getCode1" v-if="!isCounting" style="line-height: 20px; width: 130px">
                {{ buttonText }}
              </el-button>
              <el-button class="codeBtn" v-if="isCounting" style="line-height: 20px; width: 130px">{{ countdown }}s后重新发送 </el-button>
            </div>

            <div style="display: flex; justify-content: center">
              <el-button class="nax" @click="subNewPhone"> 确定 </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="tabBox" v-if="navIndex == 3">
        <div class="phone">
          <div>
            <div style="font-size: 16px; color: #333333; text-align: center">修改账号密码</div>
            <el-input type="text" class="xgsj1_old_num" v-model="psdInfo.oldPasswd" placeholder="请输入原密码" show-password />

            <div>
              <el-input type="text" class="xgsj1_old_num" placeholder="请输入新密码" v-model="psdInfo.newPasswd" show-password />
              <div style="font-size: 12px; margin-top: 5px; color: #ff8000">密码6-25个字符，区分大小写，不能包含中文和特殊字符</div>
            </div>

            <div><el-input type="text" class="xgsj1_old_num" placeholder="请确认密码" v-model="psdInfo.qPasswd" show-password /></div>

            <div style="display: flex; justify-content: center">
              <el-button class="nax" @click="subPsd"> 保存 </el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="tabBox" v-if="navIndex == 4">
        <div>
          <el-table :data="ressList" style="width: 100%">
            <el-table-column prop="name" label="姓名" width="180" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号码" align="center"> </el-table-column>
            <el-table-column prop="address" label="地址" align="center"> </el-table-column>
            <el-table-column prop="postcode" label="邮编" align="center"> </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <div style="color: #2878ff; cursor: pointer" @click="updataAddres(scope.row)">编辑</div>
                  <div @click="delAddres(scope.row)" style="margin-left: 15px; cursor: pointer; color: #b93b3b">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div style="display: flex; justify-content: center; margin-top: 50px">
            <el-button style="background: #2878ff; color: #fff" @click="address"> + 新增寄送地址 </el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加地址 -->
    <el-dialog :visible.sync="addDialogVisible" title="添加地址" width="432px">
      <div class="flex" style="justify-content: center">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名 :" prop="name">
            <el-input style="width: 200px" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码  :" prop="phone">
            <el-input style="width: 200px" v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="所在地区 :" prop="area">
            <el-cascader v-model="ruleForm.area" :options="optionsArea" :props="props"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址  :" prop="address">
            <el-input v-model="ruleForm.address" style="width: 280px" type="textarea" :rows="3"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码 :" prop="postcode">
            <el-input style="width: 200px" v-model="ruleForm.postcode"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="ruleForm.isdefault" label="02">设为默认收货地址</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addresss('ruleForm')">确定添加</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 修改地址 -->
    <el-dialog :visible.sync="UpdataDialogVisible" title="修改地址" width="30%">
      <div class="flex" style="justify-content: center">
        <el-form :model="UpdataForm" :rules="Updatarules" ref="UpdataForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名 :" prop="name">
            <el-input style="width: 200px" v-model="UpdataForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码  :" prop="phone">
            <el-input style="width: 200px" v-model="UpdataForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="所在地区 :" prop="area">
            <el-cascader v-model="UpdataForm.area" :options="optionsArea" :props="props"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址  :" prop="address">
            <el-input v-model="UpdataForm.address" style="width: 280px" type="textarea" :rows="3"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码 :" prop="postcode">
            <el-input style="width: 200px" v-model="UpdataForm.postcode"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="check" :checked="check">设为默认收货地址</el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button @click="UpdataDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updataResss('UpdataForm')">确定修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { getUser, getTitleList, doUpdateUserPasswd, updateUser, sendDx2, checkCode, doUpdateMobile } from '@/api/study.js';
import { getUserAddressList, addUserAddress, updateUserAddress } from '@/api/cert.js';
import { captchaImage, sendDx, getAllAreaList } from '@/api/regist.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      navIndex: 1, //切换
      imageUrl: '',
      form: {},
      userInfo: {
        userName: '',
        cardno: '',
        realname: '',
        sex: '',
        bindMobile: '',
        imgCode: '',
        email: '',
        phone: '',
        yzm: '',
        userAddress: '',
        organization: '',
        area: '',
        address: '',
        title: '',
        degree: ''
      }, //用户信息
      optionsArea: [],
      upImg: '',
      props: {
        value: 'CODEID',
        label: 'CITYNAME'
      },
      props1: {
        value: 'CODE_NO',
        label: 'CODE_NAME'
      },
      uploadUrl: window.glob.baseURL + '/common/upload', // 上传的图片服务器地址
      headers: {},
      countdown: 60, // 默认倒计时为30秒
      buttonText: '获取验证码', // 按钮文字
      isCounting: false, // 标记是否正在进行倒计时
      zcList: [], //职称list
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号不符合规则！', trigger: 'blur' }
        ],
        // area: [{ required: true, message: '请选择区域', trigger: 'change' }],
        address: [{ min: 2, max: 25, message: '长度在 2 到 25 个字符', trigger: 'blur' }],
        postcode: [{ required: true, message: '请输入邮政编码！', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        cardno: [{ required: true, message: '请输入身份证', trigger: 'blur' }],
        realname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        // bindMobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        organization: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          { min: 2, max: 25, message: '长度在 2 到 25 个字符', trigger: 'blur' }
        ],
        // email: [
        //   { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        //   { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        // ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        area: [{ required: true, message: '请选择地区', trigger: 'change' }],
        title: [{ required: true, message: '请选择职称', trigger: 'change' }]
      },
      codeQuerty: {}, //验证码
      phoneNext: false,
      codeImg: '',
      uuid: '',
      psdInfo: {},
      timer: null,
      addDialogVisible: false,
      UpdataDialogVisible: false,
      Updatarules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号不符合规则！', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择区域', trigger: 'change' }],
        address: [
          { required: true, message: '请输入详细地址！', trigger: 'blur' },
          { min: 2, max: 25, message: '长度在 2 到 25 个字符', trigger: 'blur' }
        ],
        postcode: [{ required: true, message: '请输入邮政编码！', trigger: 'blur' }]
      },
      optionsArea: [],
      ruleForm: {},
      UpdataForm: {},
      ressList: [],

      num: 1,
      check: false,
      bindMobileShow: false, //是否可以修改手机号

    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleCheckedChange() {
      // 点击后参数已经发生变化，重新渲染
      this.$forceUpdate();
    },
    updataAddres(row) {
      this.UpdataForm = row;
      this.check = this.UpdataForm.isdefault == '02' ? true : false;
      console.log(this.UpdataForm);
      this.num = this.num + 1;
      this.UpdataDialogVisible = true;
      getAllAreaList({}).then(res => {
        this.optionsArea = res.data.data;
      });
    },
    //删除寄送地址
    delAddres(row) {
      this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let data = row;
          data.delflag = '01';
          updateUserAddress(data).then(res => {
            console.log(res.data.code);
            if (res.data.code == '200') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getUserAddressList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    getUserAddressList() {
      getUserAddressList({}).then(res => {
        console.log(res.data.data);
        this.ressList = res.data.data;
      });
    },
    //确定修改
    updataResss(formName) {
      if (!Array.isArray(this.UpdataForm.area)) {
        this.UpdataForm.area = [this.UpdataForm.area];
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.UpdataForm.area = this.UpdataForm.area[this.UpdataForm.area.length - 1];
          this.UpdataForm.isdefault = this.check == true ? '02' : '01';
          updateUserAddress(this.UpdataForm).then(res => {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.UpdataDialogVisible = false;
            getUserAddressList({}).then(res => {
              console.log(res.data.data);
              this.ressList = res.data.data;
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //确定添加
    addresss(formName) {
      if (!Array.isArray(this.ruleForm.area)) {
        this.$message('请选择地区！');
        return false;
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.area = this.ruleForm.area[this.ruleForm.area.length - 1];
          this.ruleForm.isdefault = this.ruleForm.isdefault == true ? '02' : '01';
          addUserAddress(this.ruleForm).then(res => {
            console.log(res.data);
            this.addDialogVisible = false;
            getUserAddressList({}).then(res => {
              console.log(res.data.data);
              this.ressList = res.data.data;
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //添加地址弹框
    address() {
      this.addDialogVisible = true;
      this.ruleForm = {};
      getAllAreaList({}).then(res => {
        this.optionsArea = res.data.data;
      });
    },
    //修改密码
    subPsd() {
      if (this.psdInfo.newPasswd.length < 6) {
        this.$message('新密码最少为6位！');
        return false;
      } else if (this.psdInfo.newPasswd.length > 25) {
        this.$message('新密码最大为25位！');
        return false;
      }
      if (this.psdInfo.newPasswd !== this.psdInfo.qPasswd) {
        this.$message('两次输入密码不一致！');
        return false;
      }
      doUpdateUserPasswd(this.psdInfo).then(res => {
        console.log(res.data);
        if (res.data.code == '500') {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        } else {
          this.$message({
            message: '修改密码成功！',
            type: 'success'
          });
          this.navIndex = 1;
        }
      });
    },
    //新手机号修改
    subNewPhone() {
      if (this.codeQuerty.bindMobile == '' || this.codeQuerty.imgCode == '' || this.codeQuerty.mobileCode == '') {
        return false;
      }
      this.codeQuerty.logo = this.uuid;
      doUpdateMobile(this.codeQuerty).then(res => {
        console.log(res.data.data.code);
        if (res.data.data.code == '20') {
          this.$message({
            message: '修改手机号成功！',
            type: 'success'
          });
          this.navIndex = 1;
          getUser().then(res => {
            this.userInfo = res.data.data;
          });
        } else {
          this.$message(res.data.data.msg);
          clearInterval(this.timer);
          this.resetButtonState();
        }
      });
    },
    //获取图片验证码
    getImgCode() {
      captchaImage().then(res => {
        this.codeImg = 'data:image/png;base64,' + res.data.img;
        this.uuid = res.data.uuid;
      });
    },
    //下一步
    checkCode() {
      this.codeQuerty.bindMoblie = this.userInfo.bindMobile;
      if (this.codeQuerty.mobileCode == '' || this.codeQuerty.mobileCode == undefined) {
        return false;
      }
      checkCode(this.codeQuerty).then(res => {
        console.log(res.data.code);
        if (res.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: 'warning'
          });
        } else {
          clearInterval(this.timer);
          this.phoneNext = true;
          this.codeQuerty = {};
          this.getImgCode();
          this.resetButtonState();
        }
      });
    },
    getCode2() {
      let query = {
        bindMobile: this.userInfo.bindMobile,
        imgCode: this.userInfo.imgCode,
        logo: this.uuid
      };
      if (this.userInfo.bindMobile !== '' || this.userInfo.bindMobile !== undefined) {
        var re = /^1\d{10}$/;
        if (!re.test(this.userInfo.bindMobile)) {
          this.$message('手机号码格式不正确！');
          return false;
        }
      }
      sendDx(query).then(res => {
        console.log(res);
        if (res.data.data.code == 1) {
          //倒计时逻辑
          if (!this.isCounting) {
            this.startCountDown();
            // 这里可以写发送验证码的逻辑
            console.log('发送验证码');
          }
        } else {
          this.$message({
            message: res.data.data.msg,
            type: 'warning'
          });
          this.getImgCode();
          return false;
        }
      });
    },
    //获取新手机号验证码
    getCode1() {
      let query = {
        bindMobile: this.codeQuerty.bindMobile,
        imgCode: this.codeQuerty.imgCode,
        logo: this.uuid
      };
      if (this.codeQuerty.bindMobile !== '' || this.codeQuerty.bindMobile !== undefined) {
        var re = /^1\d{10}$/;
        if (!re.test(this.codeQuerty.bindMobile)) {
          this.$message('手机号码格式不正确！');
          return false;
        }
      }
      sendDx(query).then(res => {
        console.log(res);
        if (res.data.data.code == 1) {
          //倒计时逻辑
          if (!this.isCounting) {
            this.startCountDown();
            // 这里可以写发送验证码的逻辑
            console.log('发送验证码');
          }
        } else {
          this.$message({
            message: res.data.data.msg,
            type: 'warning'
          });
          this.getImgCode();
          return false;
        }
      });
    },
    //获取手机短信
    getCode() {
      let query = {
        bindMobile: this.userInfo.bindMobile
      };
      if (this.userInfo.bindMobile !== '' || this.userInfo.bindMobile !== undefined) {
        var re = /^1\d{10}$/;
        if (!re.test(this.userInfo.bindMobile)) {
          this.$message('手机号码格式不正确！');
          return false;
        }
      }
      sendDx2(query).then(res => {
        console.log(res);
        if (res.data.data.code == 1) {
          //倒计时逻辑
          if (!this.isCounting) {
            this.startCountDown();
            // 这里可以写发送验证码的逻辑
            console.log('发送验证码');
          }
        } else {
          this.$message({
            message: res.data.data.msg,
            type: 'warning'
          });
          return false;
        }
      });
    },
    startCountDown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);

          this.resetButtonState();
        }
      }, 1000);

      this.isCounting = true;
    },
    resetButtonState() {
      this.isCounting = false;
      this.countdown = 60;
      this.buttonText = '获取验证码';
    },
    //切换
    navBar(id) {
      this.codeQuerty = {};
      this.navIndex = id;
      this.phoneNext = false;
      this.isCounting = false;
      this.countdown = 60;
      this.buttonText = '获取验证码';
      clearInterval(this.timer);
      this.psdInfo = {};
    },
    handleAvatarSuccess(res, file) {
      this.userInfo.userphoto = file.response.url;
    },
    beforeAvatarUpload(file) {
      console.log(file.type);
      const isJPG = file.type === 'image/jpeg';
      const iPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isJPG || iPNG) {
      } else {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return (isJPG || iPNG) && isLt2M;
    },
    getToken() {
      this.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      };
    },
    submitFormPhoto() {
      let data = {
        userphoto: this.userInfo.userphoto
      };
      updateUser(data).then(res => {
        if (res.data.code == '200') {
          this.$message({
            message: '修改成功！',
            type: 'success'
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
          return false;
        }
        getUser().then(res => {
          this.userInfo = res.data.data;
          if (this.userInfo.bindMobile == null) {
            this.bindMobileShow = false;
            this.getImgCode();
          } else {
            this.bindMobileShow = true;
          }
          this.$emit('childEvent32', this.userInfo);
        });
      });
    },
    submitForm(formName) {
      console.log(this.userInfo.bindMobile);
      
      if (this.userInfo.bindMobile !== '' && this.userInfo.bindMobile !== null & this.userInfo.bindMobile !== undefined) {
        //判断是否有手机号
        this.userInfo.logo = this.uuid;
        if (!this.bindMobileShow) {
          if (this.userInfo.yzm == '' || !this.userInfo.yzm) {
            this.$message('请输入手机验证码！');
            return false;
          }
        }
        
        if (this.userInfo.bindMobile !== undefined) {
          console.log(this.userInfo.bindMobile);

          var re = /^1\d{10}$/;
          if (!re.test(this.userInfo.bindMobile)) {
            this.$message('手机号码格式不正确！');
            return false;
          }
        }
      }

      if (this.userInfo.email !== null) {
        if (this.userInfo.email.length > 15) {
          this.$message('电子邮箱字符过多！');
          return false;
        }
      }

      if ((this.userInfo.phone !== '') & (this.userInfo.phone !== null)) {
        var myreg = /^\d*$/;
        if (this.userInfo.phone.length > 11) {
          this.$message('请输入有效的联系电话！');
          return false;
        }
        if (!myreg.test(this.userInfo.phone)) {
          this.$message('请输入有效的联系电话！');
          return false;
        }
      }
      if (this.userInfo.userAddress !== null) {
        if (this.userInfo.userAddress.length > 25) {
          this.$message('联系地址字数过多！');
          return false;
        }
      }

      if (Array.isArray(this.userInfo.title)) {
        this.userInfo.title = this.userInfo.title[this.userInfo.title.length - 1];
      }

      if (Array.isArray(this.userInfo.area)) {
        this.userInfo.area = this.userInfo.area[this.userInfo.area.length - 1];
      }

      if (this.userInfo.area == undefined) {
        this.userInfo.area = '';
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          updateUser(this.userInfo).then(res => {
            if (res.data.code == '200') {
              this.$message({
                message: '修改成功！',
                type: 'success'
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
              return false;
            }
            getUser().then(res => {
              this.userInfo = res.data.data;
              if (this.userInfo.bindMobile == null) {
                this.bindMobileShow = false;
                this.getImgCode();
              } else {
                this.bindMobileShow = true;
              }
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getUser() {
      getUser().then(res => {
        this.userInfo = res.data.data;
        if (this.userInfo.bindMobile == null) {
          this.bindMobileShow = false;
          this.getImgCode();
        } else {
          this.bindMobileShow = true;
        }
      });
      getAllAreaList({}).then(res => {
        this.optionsArea = res.data.data;
      });
      getTitleList({}).then(res => {
        console.log(res.data.data);
        this.zcList = res.data.data;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getToken();
    this.getUser();
    this.getUserAddressList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.code {
  width: 80px;
  height: 40px;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}
.codeBtn {
  width: 110px;
  height: 40px;
  border: 1px solid #2878ff;
  opacity: 1;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: #2878ff;
  cursor: pointer;
  margin-left: 10px;
  background: #fff;
  line-height: 40px;
}
.updataImg {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 141px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 141px;
  line-height: 141px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 141px;
  display: block;
}

.in {
  width: 200px;
}
.banner1 {
  width: 988px;
  background: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  .headNav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    .navBox {
      width: 64px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      margin-right: 58px;
      color: #666666;
      cursor: pointer;
    }
    .navActive {
      border-bottom: 2px solid #2878ff;
      color: #2878ff;
    }
    .tipsRight {
      color: #ff8000;
      font-size: 14px;
      line-height: 34px;
      font-weight: 500;
    }
  }

  .tabBox {
    margin-top: 30px;
    font-size: 16px;
    min-height: 434px;
    color: #333333;
    box-sizing: border-box;
    .userInfo {
      margin-top: 44px;
      .submitBtn {
        width: 200px;
        background: #2878ff;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        cursor: pointer;
      }
    }

    .phone {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      .xgsj1_old_num {
        border: 0;
        margin-top: 35px;
        outline: none;
        width: 300px;
        height: 44px;
        border-radius: 4px;
      }
      .codeBox {
        outline: none;
        padding-left: 20px;
        border: 0;
        width: 170px;
        height: 44px;
        background: #f4f5f7;
        opacity: 1;
        margin-top: 15px;
      }
      .codeBtn {
        width: 110px;
        height: 44px;
        border: 1px solid #2878ff;
        opacity: 1;
        margin-top: 15px;
        line-height: 44px;
        font-size: 14px;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        color: #2878ff;
        cursor: pointer;
        margin-left: 20px;
        background: #fff;
      }
      .nax {
        width: 300px;
        background: #2878ff;
        opacity: 1;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        height: 44px;
        color: #fff;
        cursor: pointer;
        margin-top: 30px;
      }
    }
  }
}
</style>
