<template>
  <div>
    <div class="classBnaer">
      <div style="display: flex;align-items: center;justify-content: center;font-size: 20px;">
        暂无考试！
      </div>
      <!-- <div class="classHead">
        <div class="leftBox">
          温馨提示：要求考试的学时必须通过考试才能计入完成学时
        </div>

        <div>
          <el-date-picker size="small"
                          style="width: 120px;margin-right: 5px;"
                          type="year"
                          placeholder="选择年">
          </el-date-picker>
          <el-select size="small"
                     style="width: 105px;margin-right: 5px;"
                     placeholder="请选择">
            <el-option>
            </el-option>
          </el-select>
          <el-select size="small"
                     style="width: 105px;margin-right: 5px;"
                     placeholder="请选择">
            <el-option>
            </el-option>
          </el-select>

          <el-button icon="el-icon-search"
                     size="small"
                     type="primary">
            搜索
          </el-button>
        </div>
      </div> -->

      <!-- <div class="list">
        <div class="s2mDetail">
          <div style="display: flex;">
            <img src="https://gcjy-images-cdn.ahsjxjy.com/ahjxjy-v2/prod/class/2023/03/27/202300000201.png"
                 alt=""
                 class="s2mBg">

            <div class="s2mProd left">
              <div style="display: flex">
                <div style="font-size: 16px;color: #333333;line-height: 24px;font-weight: bold">碳达峰碳中和与绿色发展</div>

                <div style="margin-left: 10px;width: 60px;height: 24px;background: rgba(40,120,255,.15);border-radius: 4px;font-size: 12px;text-align: center;line-height: 24px;color: #2878FF">
                  未通过
                </div>

              </div>
              <div class="m4"
                   style="font-size: 12px;color: #666666;">年度：<span>2023</span></div>
              <div class="m4"
                   style="display: flex">
                <div style="font-size: 12px;color: #666666">类型：<span>公需课</span></div>
                <div style="font-size: 12px;color: #666666;margin-left: 30px">专业：<span>公需课</span></div>
              </div>
              <div style="font-size: 12px;color: #666666"
                   class="s2mHour m4">要求学时：<span>30.0</span>学时 &emsp;完成学时：<span>3.1</span>学时</div>
            </div>
          </div>

          <div class="rightBox">
            <div class="rightBtn xueke">学课</div>

            <div class="rightBtn">考试</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.classBnaer {
  width: 988px;
  background: #fff;
  padding: 20px;
  min-height: 660px;
  border-radius: 6px;
  .classHead {
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 400px;
      height: 36px;
      background: rgba(255, 128, 0, 0.1);
      border-radius: 6px;
      color: #ff8000;
      font-size: 14px;
      text-align: center;
      line-height: 36px;
    }
  }
  .list {
    .m4 {
      margin-top: 4px;
    }
    .s2mDetail {
      overflow: hidden;
      padding-top: 30px;
      min-height: 153px;
      padding-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      .s2mBg {
        width: 110px;
        height: 90px;
        border-radius: 6px;
      }

      .s2mProd {
        width: 400px;
        margin-left: 10px;
      }

      .rightBox {
        .rightBtn {
          width: 100px;
          height: 34px;
          background: #FFFFFF;
          border: 1px solid #666666;
          opacity: 1;
          border-radius: 6px;
          font-size: 14px;
          color: #666666;
          text-align: center;
          line-height: 34px;
          margin-bottom: 5px;
          cursor: pointer;
        }
        .xueke {
          border: 1px solid #2878FF !important;
          color: #2878FF !important;
        }
      }
    }
  }
}
</style>